import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ProductService} from "../product.service";
import {EditorService} from "../../editor/editor.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import { faArrowAltCircleLeft, faTimes, faCheck, faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {

  //icons
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faTimes = faTimes;
  faCheck = faCheck;
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;

  editors = [];
  types = [];
  recurrences = [];
  dtoneProducts = [];
  prefix = undefined;
  errorReference = false;
  wait = 0;
  params = undefined;
  productId;
  product;
  TYPE_VOUCHER;
  TYPE_ONE_TIME_SUBSCRIPTION;
  TYPE_RECURRING_SUBSCRIPTION;
  TYPE_RECURRING_PAYMENT;
  form: FormGroup;
  dtoneForm: FormGroup;

  @HostListener('click', ['$event.target']) onClick(target: EventTarget) {
    if (target === this.kartiModal.nativeElement) {
      this.hideKartiModal();
    } else if (target === this.dtoneModal.nativeElement) {
      this.hideDTOneModal();
    }
  }

  @ViewChild('kartiModal', { static: false }) kartiModal: ElementRef<HTMLDivElement>;
  kartiLoading = false;
  kartiResponse = null;

  @ViewChild('dtoneModal', { static: false }) dtoneModal: ElementRef<HTMLDivElement>;
  dtoneLoading = false;
  dtoneError = false;

  constructor(private productService: ProductService,
              private editorService: EditorService,
              private _router: Router,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute) {
    this.form = this.formBuilder.group({
      editor: new FormControl(''),
      reference: new FormControl('', [
        Validators.pattern("^[a-zA-Z0-9_-]{1,250}$"), Validators.required
      ]),
      label: new FormControl(''),
      type: new FormControl('', [Validators.required]),
      active: new FormControl('', [Validators.required]),
      recurrence: new FormControl(''),
      faceValue: new FormControl(''),
      faceUnit: new FormControl(''),
      oneProductOnly: new FormControl('', [Validators.required]),
      kartiBrandId: new FormControl('', []),
      kartiProductId: new FormControl('', []),
    });
    this.TYPE_RECURRING_PAYMENT = productService.TYPE_RECURRING_PAYMENT;
    this.TYPE_VOUCHER = productService.TYPE_VOUCHER;
    this.TYPE_ONE_TIME_SUBSCRIPTION = productService.TYPE_ONE_TIME_SUBSCRIPTION;
    this.TYPE_RECURRING_SUBSCRIPTION = productService.TYPE_RECURRING_SUBSCRIPTION;

    this.dtoneForm = this.formBuilder.group({
      dtoneProductId: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.productId = this.route.snapshot.paramMap.get('id');
    this.getEditors();
    this.getTypes();
    this.getRecurrences();
    this.initProduct();
  }

  getEditors() {
    var params = new HttpParams().set('isActive', "true");

    this.editorService.getEditors(params).subscribe(
        data => {
          this.editors = data.details;
          this.wait = this.wait + 1;
        },
        _ => { },
        () => { }
    );
  }

  getTypes() {
    this.productService.getProductsTypes().subscribe(
        data => {
          this.types = data.details;
          this.wait = this.wait + 1;
        },
        _ => { },
        () => { }
    );
  }

  getRecurrences() {
    this.productService.getProductsSubscriptionRecurrences().subscribe(
        data => {
          this.recurrences = data.details;
          this.wait = this.wait + 1;
        },
        _ => { },
        () => { }
    );
  }

  addPrefixReference = () => {
    var values = this.form.value;
    const editorId = this.form.controls['editor'].value;
    var prefix = this.editors.filter( (e) =>  e.id == editorId)[0]['reference']+'_';
    if ((values.reference.length - prefix.length) > 5) {
      this.errorReference = true;
    } else {
      this.errorReference = false;
    }
    if (values.reference != undefined) {
      if (values.reference.indexOf(prefix) == -1) {
        this.form.patchValue({ 'reference': prefix });
      } else if (values.reference.indexOf(prefix) != 0) {
        this.form.patchValue({ 'reference': prefix + values.reference });
      }
    } else {
      values.reference = prefix;
    }
  }

  submitForm() {
    this.form.controls.reference.markAsDirty();
    if(this.form.valid){
      var values = this.form.value;
      this.params = {
        'editor': values.editor,
        'reference': values.reference,
        'label': values.label,
        'type': values.type,
        'recurrence': values.type === this.TYPE_VOUCHER ? values.recurrence = null : values.recurrence ,
        'face_value': values.type !== this.TYPE_VOUCHER ? values.faceValue = "0.00" : values.faceValue,
        'face_unit': values.type !== this.TYPE_VOUCHER ? values.faceUnit = null : values.faceUnit,
        'is_active': values.active,
        'one_product_only': values.oneProductOnly,
        'karti_brand_id': values.kartiBrandId,
        'karti_product_id': values.kartiProductId,
      };

      if (values.kartiBrandId && values.kartiProductId) {
        this.kartiLoading = true;
        this.showKartiModal();

        this.productService
          .getKartiProduct(values.kartiBrandId, values.kartiProductId)
          .subscribe(
            (data) => {
              this.hideKartiModal();
              this.kartiLoading = false;
              this.kartiResponse = data;
              this.showKartiModal();
            },
            (err) => {
              this.hideKartiModal();
            },
          );
      } else {
        this.updateProduct();
      }
    }
  }

  updateProduct() {
    this.productService.putEditProduct(this.productId,this.params).subscribe(
      data => {
        this._router.navigate(['/product/' + data.details.id])
      },
      error => {
        this.hideKartiModal();
      },
      () => { }
    );
  }

  initProduct () {
      this.productService.getProduct(this.productId).subscribe(
          data => {
            this.product = data.details;
            this.form.patchValue({
              'editor': this.product.editor.id,
              'reference': this.product.reference,
              'label': this.product.label,
              'type': this.product.type.code,
              'recurrence': this.product.subscriptionRecurrence !== undefined ? this.product.subscriptionRecurrence.id : '',
              'faceValue': this.product.faceValue,
              'faceUnit': this.product.faceUnit,
              'active': this.product.isActive,
              'oneProductOnly': this.product.oneProductOnly,
              'kartiBrandId': this.product.kartiBrandId,
              'kartiProductId': this.product.kartiProductId,
            })
            //this.form.patchValue(this.product);
          },
          // error => { console.log(error); },
          () => { }
      );

      this.productService.getDTOneProduct(this.productId).subscribe(
        data => {
          this.dtoneProducts = data.details;
        },
        // error => { console.log(error); },
      );
  }

  hideKartiModal() {
    this.kartiResponse = null;
    this.kartiModal.nativeElement.style.display = 'none';
  }

  showKartiModal() {
    this.kartiModal.nativeElement.style.display = 'block';
  }

  hideDTOneModal() {
    this.dtoneError = null;
    this.dtoneForm.get('dtoneProductId').patchValue('');
    this.dtoneForm.get('dtoneProductId').markAsPristine();
    this.dtoneForm.get('dtoneProductId').markAsUntouched();
    this.dtoneModal.nativeElement.style.display = 'none';
  }

  showDTOneModal() {
    this.dtoneModal.nativeElement.style.display = 'block';
  }

  addDTOneProduct() {
    this.dtoneForm.get('dtoneProductId').markAsTouched();
    this.dtoneForm.get('dtoneProductId').markAsDirty();
    if (this.dtoneForm.valid) {
      this.dtoneLoading = true;
      this.productService.postDTOneProduct(this.productId, this.dtoneForm.value).subscribe(
        data => {
          this.dtoneLoading = false;
          this.dtoneProducts = [...this.dtoneProducts, data.details];
          this.hideDTOneModal();
        },
        error => {
          this.dtoneLoading = false;
          this.dtoneError = typeof error.error.details === 'string' ?
            error.error.details :
            'An error occurred';
        },
      );
    }
  }

  removeDTOneProduct(id) {
    this.productService.deleteDTOneProduct(id).subscribe(
      _ => {
        this.dtoneProducts = this.dtoneProducts.filter(product => product.id != id);
      },
      //error => console.log(error),
    );
  }
}
