import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../product.service';
import {
  faEdit,
  faArrowAltCircleLeft,
  faCheckCircle,
  faTimesCircle,
  faTimes,
  faCheck,
  faPlusCircle,
  faArrowAltCircleRight,
  faMinusCircle
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html'
})
export class ProductDetailComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  //icons
  faEdit = faEdit;
  faArrowAltCircleRight = faArrowAltCircleRight;
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faTimes = faTimes;
  faCheck = faCheck;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;

  distributableProducts = [];
  dtoneProducts = [];
  product;
  id = undefined;
  wait = true;
  TYPE_VOUCHER;
  TYPE_ONE_TIME_SUBSCRIPTION;
  TYPE_RECURRING_SUBSCRIPTION;

  constructor(private productService: ProductService, private route: ActivatedRoute) {
    this.TYPE_VOUCHER = productService.TYPE_VOUCHER;
    this.TYPE_ONE_TIME_SUBSCRIPTION = productService.TYPE_ONE_TIME_SUBSCRIPTION;
    this.TYPE_RECURRING_SUBSCRIPTION = productService.TYPE_RECURRING_SUBSCRIPTION;
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getProduct();
  }

  getProduct() {
    this.productService.getProductWithDistributableProduct(this.id).subscribe(
      data => {
        this.product = data.details.product;
        this.distributableProducts = data.details.distributable_products;
        this.dtoneProducts = data.details.dtoneProducts;
        this.wait = false;
      },
      error => { console.log(error); },
      () => { }
    );
  }


  changeProductActivation() {
    var params = { 'active': !this.product.active };
    this.productService.putEditProductActivation(this.id, params).subscribe(
      data => {
        this.ngOnInit();
      },
      error => { console.log(error); },
      () => { }
    );
  }

  changeDistributableProductActivation(distributableProductNumber, isActive) {
    this.productService.editDistributableProductActivation(distributableProductNumber).subscribe(
      data => {
        this.ngOnInit();
      },
      error => { console.log(error); },
      () => { }
    );
  }
}
