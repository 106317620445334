import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../transaction.service';
import { FileSaverService } from 'ngx-filesaver';
import { DatePipe, formatDate } from '@angular/common'
import { Observable, Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DistributorService } from 'src/app/distributor/distributor.service';
import { CountryService } from 'src/app/country/country.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction-list.component.html',
  styleUrls: ["./transaction-list.component.css"]
})
export class TransactionComponent implements OnInit {

  form: FormGroup;
  exportFilterSelect: FormControl;
  datasUpdater: Subject<any>;
  datas: any;
  paginationParams: any;
  exportLimitExceeded: boolean = false;
  columns: Array<any> = [
    { label: 'TRANSACTION.Date_Time_GMT', definition: 'creationDate', type: 'date', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'TRANSACTION.Tx_Unique_ID', definition: 'reference', type: 'link', sortable: true, link : { url :'/transaction/' , title : 'TEMPLATE.LIST.See_Details'}, style: {resizeable: false, flexGrow: 2, minWidth: 150 }},
    { label: 'TRANSACTION.Distributor_Site_Id', definition: 'distributionSiteOrderId', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 2, minWidth: 75 }},
    { label: 'TRANSACTION.Distribution_site_customer_id', definition: 'distributionSiteCustomerId', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 2, minWidth: 75 }},
    { label: 'TRANSACTION.nbProducts', definition: 'nbProducts', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'TRANSACTION.Products', definition: 'productList', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'PRODUCT.Inclusive_Tax_Value', definition: 'inclusiveTaxValue', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'TRANSACTION.Currency', definition: 'currency', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'TRANSACTION.Paiement_Date_GMT', definition: 'paiementDate', type: 'date', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'TRANSACTION.Distributor', definition: 'distributor', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'TRANSACTION.Country', definition: 'country', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'TRANSACTION.Tx_Status', definition: 'status', type: 'text-translate', translatePrefix: "TRANSACTION.STATUS.", sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
  ];
  actions: Array<any> = [];

  countries = [];
  distributors = [];
  status = [];
  exportFilters = [];

  constructor(
    private transactionService: TransactionService,
    private fileSaverService: FileSaverService,
    public datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private distributorService: DistributorService,
    private countryService: CountryService,
    private translateService: TranslateService
  ) {
      this.form = this.formBuilder.group({
        createdAt: [null],
        reference: [null],
        distributionSiteOrderId: [null],
        distributionSiteCustomerId: [null],
        nbProducts: [null],
        productList: [null],
        inclusiveTaxValue: [null],
        currency: [null],
        paiementDate: [null],
        distributor: [null],
        country: [null],
        status: [null],
        voucherSerialNumber: [null],
      });

      this.exportFilterSelect = new FormControl(0);
  }

  ngOnInit() {
    this.distributorService.getDistributors().subscribe((response : any) => {
      this.distributors = response.details.map(element => ({ name: element.name, value: element.id }));
    });

    this.countryService.getCountries().subscribe((response : any) => {
      this.countries = response.details.map(element => ({ name: element.name, value: element.id }));
    });

    this.transactionService.getTransactionStatus().subscribe((response : any) => {
      this.status = response.details.map(element => ({ name: element.label, value: element.id }));
      const paidStatus = response.details.find((s) => s.code === 'PAID');
      const doneStatus = response.details.find((s) => s.code === 'DONE');

      if (paidStatus && doneStatus) {
        this.status.push({ name: 'Paid+Done', value: [paidStatus.id, doneStatus.id] });
      }
    });

    this.translateService.get('TRANSACTION').subscribe((value) => {
      this.exportFilters = [
        { name: value['Filter_Default'], value: 0 },
        { name: value['Filter_PaidDone_Current_Month'], value: 1 },
        { name: value['Filter_PaidDone_Previous_Month'], value: 2 },
        { name: value['Filter_PaidDone_Mali'], value: 3 },
      ];
    });
  }

  initData = (event: Subject<any>): void => {
    this.datasUpdater = event;
    this.datasUpdater.next({ filter: {}, predicate:'creationDate', sort: 'desc' });
  }

  getData = (data) => {
    this.datas = data.data;
  }

  getRequest = (param: any = {}): Observable<any> => {
    return this.transactionService.getTransactions(param);
  }

  getPaginationInfo = (info) => {
    this.paginationParams = info;
  }

  updateData = (event: any = null) => {
    this.datasUpdater.next({ ...this.paginationParams, filter: this.getFiltersValues() });
  }

  getFiltersValues = () => {
    let dateTo:Date = null;
    let dateFrom:Date = null;

    const status = this.form.get('status').value;
    if (status && status.includes(',')) {
      this.form.get('status').setValue(status.split(','));
    }

    if (this.form.get('createdAt').value) {
      dateTo = this.form.get('createdAt').value[0];
      dateTo.setHours(dateTo.getHours() - dateTo.getTimezoneOffset() / 60);

      dateFrom = this.form.get('createdAt').value[1];
      dateFrom.setHours(dateFrom.getHours() - dateFrom.getTimezoneOffset() / 60);
      this.form.get('createdAt').setValue([dateTo, dateFrom])
    }
    
    if (this.form.get('paiementDate').value) {
      dateTo = this.form.get('paiementDate').value[0];
      dateTo.setHours(dateTo.getHours() - dateTo.getTimezoneOffset() / 60);

      dateFrom = this.form.get('paiementDate').value[1];
      dateFrom.setHours(dateFrom.getHours() - dateFrom.getTimezoneOffset() / 60);
      this.form.get('paiementDate').setValue([dateTo, dateFrom])
    }

    const filters: any = Object.keys(this.form.value)
      .filter((k) => this.form.value[k] !== null && this.form.value[k] !== '')
      .reduce((a, k) => ({ ...a, [k]: this.form.value[k] }), {});

    return filters;
  }

  clearFilters() {
    this.form.reset();
    this.updateData();
  }

  exportTransactions() {
    const exportFilter = this.exportFilterSelect.value;
    let filters = this.getFiltersValues();

    if (exportFilter) {
      Object.keys(filters).forEach((k) => filters[k] = null);
      filters.status = this.status.find((s) => s.name === 'Paid+Done').value;

      const now = new Date();
      let dateTo = null;
      let dateFrom = null;
      
      if (exportFilter == 1) { // Filter_PaidDone_Current_Month
        dateTo = new Date(now.getFullYear(), now.getMonth(), 1);
        dateFrom = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      } else if (exportFilter == 2) { // Filter_PaidDone_Previous_Month
        dateTo = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        dateFrom = new Date(now.getFullYear(), now.getMonth(), 0);
      } else if (exportFilter == 3) { // Filter_PaidDone_Mali
        dateTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
        dateFrom = now;
        filters.country = this.countries.find((c) => c.name.toUpperCase() === 'MALI').value;
      }

      filters.createdAt = [dateTo, dateFrom];
    }

    this.exportLimitExceeded = false;
    this.transactionService.getCanExportTransactions({ ...filters }).subscribe((response: any) => {
      this.transactionService.exportTransactions({ ...filters }).subscribe(
        data => {
          var filename = "Export Transactions OLink agg " + this.datepipe.transform(new Date(), 'ddMMyyyy');
          this.fileSaverService.save(data, filename + '.csv');
        },
        error => { 
          console.log(error); 
        },
        () => { }
      );  
    }, error => {
      if (error.error.details.code === "EXPORT_LIMIT_EXCEEDED") {
        this.exportLimitExceeded = true;
      }
    });
  }
}
