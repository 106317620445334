import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../product.service';
import { EditorService } from '../../editor/editor.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { DistributorService } from '../../distributor/distributor.service';
import { faTimes, faArrowAltCircleLeft, faCheck} from '@fortawesome/free-solid-svg-icons';
import {DistributionSiteService} from '../../distribution-site/distribution-site.service';

@Component({
  selector: 'app-distributable-product-new',
  templateUrl: './distributable-product-new.component.html',
  styleUrls: ['./distributable-product-new.component.css']
})
export class DistributableProductNewComponent implements OnInit {

  //icons
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faTimes = faTimes;
  faCheck = faCheck;

  distributors = [];
  countries = [];
  distributionSites = [];
  paymentMeans: Array<any>;
  productId = undefined;
  product = undefined;
  wait = 0;
  errorShares = false;
  sharesOk = false;
  shares = 0;
  preTaxValue = undefined;
  promoPreTaxValue = undefined;
  currency = undefined;
  params = undefined;

  form: FormGroup;

  constructor(private productService: ProductService, private distributorService: DistributorService, private distributorSiteService: DistributionSiteService, private route: ActivatedRoute, private _router: Router, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      distributor: new FormControl(''),
      country: new FormControl({ value: "", disabled: true }),
      distribution_site: new FormControl({ value: "", disabled: true }),
      vat_rate: new FormControl(''),
      inclusive_tax_value: new FormControl('', Validators.required),
      promo_inclusive_tax_value: new FormControl(''),
      editor_share: new FormControl(''),
      distributor_share: new FormControl(''),
      distribution_site_share: new FormControl(''),
      orange_link_share: new FormControl(''),
      payment_means: this.formBuilder.array([], [Validators.required]),
      reference: new FormControl('', [Validators.pattern("^[a-zA-Z0-9_-]{1,250}$"), Validators.required]),
      sold_value: new FormControl({ value: '', disabled: true }),
    })
  }

  ngOnInit() {
    this.productId = this.route.snapshot.paramMap.get('id');
    this.getProduct(this.productId);
    this.getAvailableDistributors(this.productId);
  }

  getProduct(productId) {
    this.productService.getProduct(productId).subscribe(
      data => {
        this.product = data.details;
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getAvailableDistributors(productId) {
    this.productService.getProductAvailableDistributors(productId).subscribe(
      data => {
        this.distributors = data.details;
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getAvailableCountries() {
    var distributorId = this.form.value.distributor;
    this.distributorService.getDistributorCountries(distributorId).subscribe(
      data => {
        this.countries = data.details;
        this.form.controls['country'].enable();
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getAvailableDistributionSites() {
    const countryId = this.form.value.country;
    var distributorId = this.form.value.distributor;
    this.currency = this.countries.find(({ id }) => id == countryId);
    this.distributorService.getDistributorDistributionSitesByCountry(distributorId, countryId).subscribe(
      data => {
        this.distributionSites = data.details;
        this.form.controls['distribution_site'].enable();
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getAvailablePaymentMeans() {
    var distibutionSiteId = this.form.value.distribution_site;
    this.distributorSiteService.getPaymentMeansByDistributionSiteId(distibutionSiteId).subscribe(
        data => {
          this.paymentMeans = data.details;
          const pm: FormArray = this.form.get('payment_means') as FormArray;
          pm.clear();
        },
        error => { console.log(error); },
        () => { }
    );
  }

  managePaymentMeanActivation(e) {
    const pm: FormArray = this.form.get('payment_means') as FormArray;
    if (e.target.checked) {
      pm.push(new FormControl(e.target.value));
    } else {
      const index = pm.controls.findIndex(x => x.value === e.target.value);
      pm.removeAt(index);
    }
  }

  checkTotalShares() {
    var editorShare = this.form.value.editor_share;
    var distributorShare = this.form.value.distributor_share;
    var distributionSiteShare = this.form.value.distribution_site_share;
    var orangeLinkShare = this.form.value.orange_link_share;
    this.sharesOk = false;
    this.shares = Number(editorShare) + Number(distributorShare) + Number(orangeLinkShare) + Number(distributionSiteShare);
    if (this.shares > 100 || this.shares <100) {
      this.errorShares = true;
      return;
    }
    else if (editorShare != undefined && distributorShare != undefined && orangeLinkShare != undefined && distributionSiteShare != undefined && this.shares == 100) {
      this.errorShares = false;
      this.sharesOk = true;
      return;
    }
    this.errorShares = true;
  }

  calculatePreTaxValue() {
    var VATRate = this.form.value.vat_rate;
    var inclusiveTaxValue = this.form.value.inclusive_tax_value;
    this.preTaxValue = (inclusiveTaxValue / (1 + (VATRate) / 100)).toFixed(2);
  }

  calculatePromoPreTaxValue() {
    var VATRate = this.form.value.vat_rate;
    var promoInclusiveTaxValue = this.form.value.promo_inclusive_tax_value;
    this.promoPreTaxValue = (promoInclusiveTaxValue / (1 + (VATRate) / 100)).toFixed(2);
  }

  submitForm() {
    this.form.controls.reference.markAsDirty();
    var values = this.form.value;
    this.calculatePreTaxValue();
    this.params = {
      'distribution_site': values.distribution_site,
      'product': this.productId,
      'pre_tax_value': this.preTaxValue,
      'promo_pre_tax_value': this.promoPreTaxValue === '0.00' ? null : this.promoPreTaxValue,
      'inclusive_tax_value': values.inclusive_tax_value === '' ? 0 : values.inclusive_tax_value,
      'promo_inclusive_tax_value': values.promo_inclusive_tax_value === '' ? 0 : values.promo_inclusive_tax_value,
      'vat_rate': values.vat_rate === '' ? 0 : values.vat_rate,
      'orange_link_share': values.orange_link_share === '' ? 0 : values.orange_link_share,
      'editor_share': values.editor_share === '' ? 0 : values.editor_share,
      'distributor_share': values.distributor_share === '' ? 0 : values.distributor_share,
      'distribution_site_share': values.distribution_site_share === '' ? 0 : values.distribution_site_share,
      'reference': values.reference,
      'payment_means': values.payment_means,
    };
    if(this.form.valid){
      this.productService.postNewDistributableProduct(this.params).subscribe(
          data => {
            this._router.navigate(['product/' + this.productId])
          },
          error => {
            console.log(error);
          },
          () => { }
      );
    }else{
      this.checkTotalShares();
    }

  }

  isCountryResale(countryId): boolean {
    return !!this.countries.find(({ id, billing_type_code }) => id == countryId && billing_type_code === 'ACH');
  }
}
