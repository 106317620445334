import { Component, OnInit } from '@angular/core';
import {ProductService} from "../product.service";
import {ActivatedRoute, Router} from "@angular/router";
import { faTimes, faCheck, faEdit, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DistributorService} from "../../distributor/distributor.service";
import {DistributionSiteService} from "../../distribution-site/distribution-site.service";
import {forkJoin} from "rxjs";


@Component({
    selector: 'app-edit-distributable-product',
    templateUrl: './edit-distributable-product.component.html',
    styleUrls: ['./edit-distributable-product.component.css']
})
export class EditDistributableProductComponent implements OnInit {

    generalInfoProductForm: FormGroup;
    detailDistribuableProductForm: FormGroup;

    //icons
    faArrowAltCircleLeft = faArrowAltCircleLeft;
    faTimes = faTimes;
    faCheck = faCheck;
    faEdit = faEdit;

    loadingProduct: boolean = true;
    loadingDistributable: boolean = true;
    loadingDistributor: boolean = true;

    distributableId = undefined;
    distributableProduct : any = [];
    distributableProductPrice: any = [];
    product : any;
    productId;
    distributors: any = [];
    countries: any = [];
    currency: any ;
    distributionSites : any = [];
    paymentMeans: any = [];
    paymentMeansSelectionned: any = FormArray;

    errorShares = false;
    sharesOk = false;
    shares = 0;

    preTaxValue : any;
    promoPreTaxValue : any;

    conversionRate: number;

    loading = false;
    TYPE_ONE_TIME_SUBSCRIPTION;
    TYPE_RECURRING_SUBSCRIPTION;


    constructor(private productService: ProductService,
                private route: ActivatedRoute,
                private formBuilder: FormBuilder,
                private distributorService: DistributorService,
                private distributorSiteService: DistributionSiteService,
                private router: Router) {
        this.TYPE_ONE_TIME_SUBSCRIPTION = productService.TYPE_ONE_TIME_SUBSCRIPTION;
        this.TYPE_RECURRING_SUBSCRIPTION = productService.TYPE_RECURRING_SUBSCRIPTION;
        this.distributableProduct = { distributionSite: { distributor : {}, country: {}}};
        this.product = {editor : {}, type: {}};
        this.generalInfoProductForm = this.formBuilder.group({
            editor: new FormControl(''),
            reference: new FormControl(''),
            label: [''],
            type : [''],
            subscriptionRecurence: [''],
            isActive: ['']
        });

        this.detailDistribuableProductForm = this.formBuilder.group({
            distributor : [''],
            reference: [''],
            country: [''],
            distributionSite: [''],
            editorShare: [''],
            distributorShare: [''],
            vatRate: [''],
            distributionSiteShare: [''],
            inclusiveTaxValue: ['', Validators.required],
            promoInclusiveTaxValue: [''],
            orangeLinkShare: [''],
            paymentMeans: this.formBuilder.array([], [Validators.required]),
            soldValue: [''],
            inclusiveTaxValueEUR: [{ value: '', disabled: true }],
        });
    }

    ngOnInit() {
        this.distributableId = this.route.snapshot.paramMap.get('id-dist');
        this.getDistributableProduct(this.distributableId);
        this.productId = this.route.snapshot.paramMap.get('id');
        this.getProduct(this.productId);
        this.getAvailableDistributors(this.productId);
    }

    getProduct(productId) {
        this.productService.getProduct(productId).subscribe(
            data => {
                this.product = data.details;
                this.loadingProduct = false;
            },
            error => { 
                this.loadingProduct = false;
                console.log(error); 
            },
            () => { }
        );
    }

    getAvailableDistributors(productId) {
        this.productService.getProductAvailableDistributors(productId).subscribe(
            data => {
                this.distributors = data.details;
                this.loadingDistributor = false;
            },
            error => {
                this.loadingDistributor = false;
                console.log(error);
            },
            () => { }
        );
    }

    getDistributableProduct = (distributableId) => {
        this.productService.getDistributableProduct(distributableId).subscribe(
            data => {
                this.distributableProduct = data.details;
                this.product = data.details.product;
                this.productId = this.product.id;
                this.getDistributableProductPrice(distributableId);
                this.getConversionRate(distributableId);
            },
            error => { console.log(error); },
            () => { }
        );
    }

    getDistributableProductPrice = (distributableId) => {
        this.productService.getActiveDistributableProductPriceByDistributableProduct(distributableId).subscribe(
            data => {
                this.distributableProductPrice = data.details;
                this.initDetailDistribuableProductForm();
                this.loadingDistributable = false
            },
            error => {
                console.log(error);
                this.loadingDistributable = false;
            },
            () => { }
        );
    }

    getConversionRate = (distributableId) => {
        this.productService.getDistributableProductConversionRate(distributableId).subscribe(
            data => {
                this.conversionRate = Number(data.details.rate);
                this.calculateInclusiveTaxValueEUR();
                this.loadingDistributable = false
            },
            error => {
                console.log(error);
                this.loadingDistributable = false;
            },
            () => { }
        );
    }

    getAvailableCountries() {
        var distributorId = this.detailDistribuableProductForm.value.distributor;
        this.distributorService.getDistributorCountries(distributorId).subscribe(
            data => {
                this.countries = data.details;
                this.detailDistribuableProductForm.controls['country'].enable();
            },
            error => { console.log(error); },
            () => { }
        );
    }

    getAvailableDistributionSites() {
        var countryId = this.detailDistribuableProductForm.value.country;
        var distributorId = this.detailDistribuableProductForm.value.distributor;
        this.distributorService.getDistributorDistributionSitesByCountry(distributorId, countryId).subscribe(
            data => {
                this.distributionSites = data.details;
                this.detailDistribuableProductForm.controls['distributionSite'].enable();
            },
            error => { console.log(error); },
            () => { }
        );
    }

    checkTotalShares() {
        var editorShare = this.detailDistribuableProductForm.value.editorShare;
        var distributorShare = this.detailDistribuableProductForm.value.distributorShare;
        var distributionSiteShare = this.detailDistribuableProductForm.value.distributionSiteShare;
        var orangeLinkShare = this.detailDistribuableProductForm.value.orangeLinkShare;
        this.sharesOk = false;
        this.shares = Number(editorShare) + Number(distributorShare) + Number(orangeLinkShare) + Number(distributionSiteShare);
        if (this.shares !== 100) {
            this.errorShares = true;
            return;
        }
        else if (editorShare != undefined && distributorShare != undefined && orangeLinkShare != undefined && distributionSiteShare != undefined && this.shares == 100) {
            this.errorShares = false;
            this.sharesOk = true;
            return;
        }
        this.errorShares = false;
    }

    getAvailablePaymentMeans = () =>  {
        var distibutionSiteId = this.detailDistribuableProductForm.value.distributionSite;
        this.distributorSiteService.getPaymentMeansByDistributionSiteId(distibutionSiteId).subscribe(
            data => {
                this.paymentMeans = data.details;
                const pm: FormArray = this.detailDistribuableProductForm.get('paymentMeans') as FormArray;
                pm.clear();
                this.initialisePaymementMeans();
            },
            error => { console.log(error); },
            () => { }
        );
    }

    calculatePreTaxValue () {
        var VATRate = this.detailDistribuableProductForm.value.vatRate;
        var inclusiveTaxValue = this.detailDistribuableProductForm.value.inclusiveTaxValue;
        this.preTaxValue = (inclusiveTaxValue / (1 + (VATRate) / 100)).toFixed(2);
    }

    calculatePromoPreTaxValue() {
        var VATRate = this.detailDistribuableProductForm.value.vatRate;
        var promoInclusiveTaxValue = this.detailDistribuableProductForm.value.promoInclusiveTaxValue;
        this.promoPreTaxValue = (promoInclusiveTaxValue / (1 + (VATRate) / 100)).toFixed(2);
    }
    
    calculateInclusiveTaxValueEUR() {
        const inclusiveTaxValue = this.detailDistribuableProductForm.get('inclusiveTaxValue').value;
        if (!this.conversionRate || !inclusiveTaxValue) {
            this.detailDistribuableProductForm.get('inclusiveTaxValueEUR').setValue(null);
        } else {
            this.detailDistribuableProductForm.get('inclusiveTaxValueEUR').setValue(Math.round(this.conversionRate * inclusiveTaxValue * 100) / 100);
        }
    }

    managePaymentMeanActivation(e, index) {
        let pm: FormArray = this.detailDistribuableProductForm.get('paymentMeans') as FormArray;
        pm.value[index].value = e.target.checked;
    }

    submitAction = () =>{
        this.detailDistribuableProductForm.controls.reference.markAsDirty();
        var values = this.detailDistribuableProductForm.value;
        this.calculatePreTaxValue();
        let editDistribuableProductparams = {
            'distribution_site': values.distributionSite,
            'product': this.productId,
            'pre_tax_value': this.preTaxValue,
            'promo_pre_tax_value': this.promoPreTaxValue === '0.00' ? null : this.promoPreTaxValue,
            'inclusive_tax_value': values.inclusiveTaxValue === '' || values.inclusiveTaxValue === null ? 0 : values.inclusiveTaxValue,
            'promo_inclusive_tax_value': values.promoInclusiveTaxValue === '' || values.promoInclusiveTaxValue === null ? 0 : values.promoInclusiveTaxValue,
            'vat_rate': values.vatRate === '' ? 0 : values.vatRate,
            'orange_link_share': values.orangeLinkShare,
            'editor_share': values.editorShare,
            'distributor_share': values.distributorShare,
            'distribution_site_share': values.distributionSiteShare,
            'reference': values.reference,
            'payment_means': values.paymentMeans.length > 0 ? values.paymentMeans.filter((pm)=> pm.value == true).map((pm)=> pm.reference): [],
            'sold_value': values.soldValue,
        };
        if(this.detailDistribuableProductForm.valid){
            this.productService.editDistributableProduct(this.distributableId,editDistribuableProductparams).subscribe(
                data => {
                    this.router.navigate(['product/' + this.productId])
                },
                error => {
                    console.log(error);
                },
                () => { }
            );
        }else{
            this.checkTotalShares();
        }
    }

    initDetailDistribuableProductForm = () => {
        let distributorId = this.distributableProduct.distributionSite.distributor.id;
        let countryId = this.distributableProduct.distributionSite.country.id;
        let distibutionSiteId = this.distributableProduct.distributionSite.id;
        this.currency = this.distributableProduct.distributionSite.country.currency;
        forkJoin([this.distributorService.getDistributorCountries(distributorId),
            this.distributorService.getDistributorDistributionSitesByCountry(distributorId, countryId),
            this.distributorSiteService.getPaymentMeansByDistributionSiteId(distibutionSiteId)]).subscribe((responses) => {
            this.countries = responses[0].details;
            this.distributionSites = responses[1].details;
            this.paymentMeans = responses[2].details;
            this.initialisePaymementMeans();

        });
        this.detailDistribuableProductForm.patchValue(
            {
                distributor: this.distributableProduct.distributionSite.distributor.id,
                reference : this.distributableProduct.reference,
                country: this.distributableProduct.distributionSite.country.id,
                editorShare: this.distributableProduct.editorShare,
                distributionSite: this.distributableProduct.distributionSite.id,
                distributorShare: this.distributableProduct.distributorShare,
                vatRate: this.distributableProduct.VATRate,
                distributionSiteShare: this.distributableProduct.distributionSiteShare,
                inclusiveTaxValue: this.distributableProductPrice.inclusiveTaxValue ? this.distributableProductPrice.inclusiveTaxValue : null,
                promoInclusiveTaxValue: this.distributableProductPrice.promoInclusiveTaxValue ? this.distributableProductPrice.promoInclusiveTaxValue : null,
                soldValue: this.distributableProductPrice.soldValue ? this.distributableProductPrice.soldValue : null,
                orangeLinkShare: this.distributableProduct.orangeLinkShare,
            }
        );
        this.calculatePreTaxValue();
        this.calculatePromoPreTaxValue();
        this.checkTotalShares();
    }

    initialisePaymementMeans = () => {
        // initialiser les button payement means
        let paymentMeansFormArray = this.detailDistribuableProductForm.get('paymentMeans') as FormArray;
        
        this.paymentMeans.forEach((paymentMeanFromList)=> {
            paymentMeansFormArray.push(new FormControl({
                reference: paymentMeanFromList.reference,
                value:  this.distributableProduct.paymentMeans.find((element : any)=> element.reference === paymentMeanFromList.reference) !== undefined
            }));
        });
       
    }

    isCountryResale(countryId): boolean {
        return !!this.countries.find(({ id, billing_type_code }) => id == countryId && billing_type_code === 'ACH');
    }
}
